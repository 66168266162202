import React from 'react';
import Box from '@material-ui/core/Box';

function SmallImageHero() {
  return (
    <React.Fragment>
      <Box bgcolor="primary.main" py={[10, 10, 14]} position="relative" />
    </React.Fragment>
  );
}

export default SmallImageHero;
